@import "./navigation-nojs.pcss";

.hero-slide-img {
  position: relative;
  backface-visibility: hidden;
}

.menu-button {
  @responsive >=ipad_landscape { display: none }
  display: flex;
  z-index: 999;
  align-self: flex-start;

  .hamburger {
    svg {
      @responsive $tablet {
        height: 40px;
      }

      g {
        transition: stroke 750ms ease;
      }
    }

    &.white {
      svg {
        g {
          stroke: #ffffff;
        }
      }
    }
  }

  a, a:visited, a:active, a:focus {
    @responsive $mobile {
      outline: none !important;
    }
  }
}

[data-script="index"] {
  .menu-button {
    svg {
      g {
        stroke: #ffffff;
      }
    }
  }
}

header[data-nav][data-header-alt-bg] {
  nav section ul > li {
    color: white !important;
  }

  nav .brand svg path {
    fill: white !important;
  }

  .menu-button {
    svg {
      g {
        stroke: #ffffff;
      }
    }
  }
}

header[data-nav] {
  @container;
  @unpack theme.header.padding.large;
  max-width: 100% !important;

  z-index: 5;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.00001);
  width: 100%;
  backface-visibility: hidden;
  transition: padding-top 500ms ease, padding-bottom 500ms ease;

  &[data-header-small] {
    @unpack theme.header.padding.small;
    transition: padding-top 500ms ease, padding-bottom 500ms ease;
  }

  nav {
    height: auto;
    position: relative;
    display: flex;
    justify-content: space-between;

    .mobile-bg {
      z-index: 3;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      transform: translateX(100%);
      background-color: theme(colors.black);
      display: none;
    }

    .brand {
      z-index: 5;
      display: flex;
      width: 100%;
      align-self: flex-start;

      &.white {
        svg {
          path {
            fill: #ffffff;
          }
        }
      }

      svg {
        @space height 18px $mobile;

        path {
          transition: fill 1s ease;
          [data-script="index"] & {
            fill: #ffffff;
          }
        }
      }
    }

    section {
      @column 5:1/12 >=ipad_landscape;
      @responsive >=ipad_landscape {
        display: block !important;
        max-width: 600px !important;
      }

      @responsive <=ipad_portrait {
        display: none;
        z-index: 5;
      }

      ul {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
        justify-content: space-between;
        flex-direction: row;

        @responsive >=ipad_landscape {
          width: 100%;
        }

        @responsive <=ipad_portrait {
          align-items: center;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          left: 0;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        > li {
          @color fg dark;

          [data-script="index"] & {
            @color fg white;
          }

          display: inline-block;
          white-space: nowrap;

          [data-script="about"] & {
            @color fg dark;
          }

          @responsive >=ipad_landscape {
            &:not(:last-of-type) {
              margin-right: 2vw;
            }
          }

          a {
            font-weight: 400;
            position: relative;
            transition: border-color 250ms ease, transform 250ms ease;
            border-bottom: 1px solid transparent;

            &:first-of-type {
              margin: 0;
            }

            &.active, &:hover {
              border-bottom: 1px solid theme(colors.red);
              transition: border-color 250ms ease, transform 250ms ease;
            }

            &:hover {
              text-decoration: none;
            }

            @responsive <=ipad_portrait {
              @font serif h2;
              color: theme(colors.light) !important;
              display: inline-block;
              text-transform: none;

              &:hover, &:active, &.active {
                color: theme(colors.light) !important;
              }
            }
          }
        }
      }
    }
  }
}

figure {
  margin: 0;
}


.open-menu {
  .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after,
  .hamburger-inner::before, .hamburger-inner::after {
    background-color: #000 !important;
  }
}