.news-detail {
  > h2 {
    @font serif h2/1.3;
    @space margin-bottom 25px $desktop;
    @space margin-bottom 25px $tablet;
    @space margin-bottom 15px $mobile;

    @column 6/12 $desktop;
    @column-offset 3:1/12 $desktop;

    @column 6/12 $tablet;
    @column-offset 3:1/12 $tablet;

    @responsive $mobile {
      font-size: 28px;
    }

    text-align: center;
  }

  .news-cover {
    @container;
  }

  > .date {
    @column 6/12;
    @column-offset 3:1/12;
    @extend %kategori;
    @space margin-bottom 25;
    padding-top: 10px;
    padding-bottom: 5px;
    text-align: center;
  }
}