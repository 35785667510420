.page-detail {
  /* @space padding-top 100; */
}

.jobs {
  @container;
  @space padding-bottom 100;
  border-bottom: 1px solid #000000;

  .inner {
    @row 2 >=ipad_landscape;
  }

  h2 {
    @font serif h3/1;
    @space padding-left 1:1/12 >=desktop_md;
    @column 4/12 >=ipad_landscape;
    @space margin-bottom 35px <=ipad_portrait;
  }

  .job-listing {
    @column 8/12 >=ipad_landscape;
    display: flex;
    flex-direction: column;
    padding-top: 10px;

    .job {
      @row 2 >=ipad_landscape;
      @space margin-bottom 80px >=ipad_landscape;
      @space margin-bottom 60px <=ipad_portrait;

      .position {
        @column 2/8 >=ipad_landscape;
        @font serif;
        font-weight: 500;
        @responsive $mobile {
          line-height: 1.85;
        }
      }

      .text {
        @column 6/8 >=ipad_landscape;
        p {
          @responsive $mobile {
            line-height: 1.85;
          }
        }
        a {
          @extend %link-arrow;
          @fontsize more/1;
          font-weight: 500;
        }
      }
    }
  }
}