%caption {
  @space margin-top 15;
  @fontsize bildetekst;
  @space min-height 30;
  white-space: normal !important;
}

%captionEmpty {
  @space margin-top 15;
  @fontsize bildetekst;
  @space min-height 30;
  white-space: normal !important;

  &:empty {
    min-height: 0;
    margin-top: 0;
    height: 0;
  }
}

%zoom {
  overflow: hidden;

  img {
    filter: grayscale(0);
    transition: transform 3s ease, filter 1.5s ease, opacity 2s ease;
    opacity: 1;
  }

  &:hover {
    img {
      transform: scale(1.05);
      filter: grayscale(100%);
      opacity: 0.9;
    }
  }
}

%zoomReverse {
  overflow: hidden;

  img {
    filter: grayscale(100%);
    transition: transform 3s ease, filter 1.5s ease, opacity 2s ease;
    opacity: 1;
  }

  &:hover {
    img {
      filter: grayscale(0);
      transform: scale(1.05);
      opacity: 0.9;
    }
  }
}

%fromGrayscale {
  overflow: hidden;

  img {
    filter: grayscale(100%);
    transition: filter 1.5s ease;
  }

  &:hover {
    img {
      filter: grayscale(0);
    }
  }
}

.red {
  @color fg red;
}

.separator {
  padding-left: 5px;
  padding-right: 5px;
}

%abs100 {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

%link {
  border-bottom: 1px solid transparent;
  transition: bordercolor 550ms ease-out;
  padding-bottom: 1px;

  &:hover {
    border-bottom: 1px solid theme(colors.red);
    transition: border-color 350ms ease-in;
    text-decoration: none;
  }
}

%link-inverted {
  border-bottom: 1px solid theme(colors.link.border);
  transition: border-color 550ms ease-out;
  padding-bottom: 3px;
  @space padding-bottom 1px $mobile;

  &:hover {
    border-bottom: 1px solid theme(colors.red);
    transition: border-color 350ms ease-in;
    text-decoration: none;
  }
}

%link-arrow {
  position: relative;
  border-bottom: 1px solid theme(colors.link.border);
  transition: border-color 550ms ease-out;
  padding-right: 18px;
  padding-bottom: 3px;
  @space padding-bottom 1px $mobile;

  &:hover {
    border-bottom: 1px solid theme(colors.red);
    transition: border-color 350ms ease-in;
    text-decoration: none;

    &:after {
      transform: translateX(5px) translateY(50%);
    }
  }

  &:after {
    position: absolute;
    right: 0;
    content: '→';
    transition: transform 500ms ease;
    transform: translateX(0) translateY(50%);
  }
}

%kategori {
  @color fg red;
  @fontsize kategori/1;
  text-transform: uppercase;
  letter-spacing: 0.89px;
}