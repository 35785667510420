a:hover {
  span[class^="arrow-"] {
    opacity: 1;
  }
}

span[class^="arrow-"] {
  @font main;
  transition: transform 150ms ease;
  color: inherit;
  font-weight: normal;
  position: relative;
}
