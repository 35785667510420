[data-v] {
  @space padding-top blockSpacing;

  &:last-of-type {
    @space padding-bottom blockSpacing;
    margin-bottom: 0 !important;
  }

  strong {
    font-weight: 600 !important;
  }

  p strong {
    font-weight: 600 !important;
  }
}