[data-ll-srcset] {
  img {
    opacity: 1;
    filter: none;
    transition: filter 0.5s ease, opacity 0.7s ease;

    &[data-ll-blurred] {
      opacity: 0.2;
      filter: blur(35px);
    }
  }
}
