[data-panner-left], [data-panner-right] {
  @fontsize 90px $desktop;
  @fontsize 80px $tablet;
  @fontsize 50px $mobile;

  position: absolute;
  top: calc(50% - 64px);
  transform: translateY(-50%);
  z-index: 2;
  color: white;
  cursor: pointer;

  @responsive $mobile {
    display: none;
  }

  [data-ie11="true"] & {
    display: none;
  }
}

[data-panner-left] {
  @space left container;
}

[data-panner-right] {
  @space right container;
}

[data-panner-indicator] {
  @space margin-top 125px $desktop;
  @space margin-top 80px $tablet;
  @space margin-top 50px $mobile;

  @space margin-left container;
  @space margin-right container;
  height: 10px;
  display: block;
  position: relative;

  &:after {
    top: 50%;
    left: 0;
    width: 100%;
    position: absolute;
    content: '';
    height: 1px;
    background-color: #000000;
  }

  [data-panner-indicator-nib] {
    display: block;
    padding-top: 15px;
    padding-bottom: 15px;
    position: relative;
    width: 50%;

    &:after {
      background-color: #000000;
      content: '';
      position: absolute;
      height: 5px;
      width: 100%;
      top: 0px;
    }
  }
}

.panner {
  &.projects {
    .inner {
      > h2 {
        @container;
        @color fg red;
        @fontsize kategori;
        @extend %kategori;
        @space margin-bottom 25;
      }

      figcaption {
        margin-top: 20px !important;
        h2 {
          @font serif h4/1;
          margin-bottom: 10px;
          font-weight: 500;
        }
      }

      [data-panner] {
        @space margin-left container;
        @space margin-right container;
        align-items: flex-start;
      }

      [data-panner-item] {
        @column 5:1/12 $desktop;
        @column 5:1/12 $tablet;
        @column 5:1/6 $mobile;

        @space padding-right 1 $desktop;
        @space padding-right 1 $tablet;
        @space padding-right 1 $mobile;

        &[data-orientation="landscape"] {
          @column 6:1/12 $desktop;
          @column 6:1/12 $tablet;
          @column 5:1/6 $mobile;
        }

        overflow: hidden;

        figcaption {
          margin-top: 15px;
          min-height: 60px;
          max-width: 400px;

          p {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .overlay-zoom {
          display: none;
        }
      }

      figure {
        @extend %zoom;
      }
    }

    .more {
      @space margin-top 100px $desktop;
      @space margin-top 100px $tablet;
      @space margin-top 40px $mobile;
      margin: 0 auto;
      text-align: center;
    }

    .arrow {
      @fontsize more/1;
      font-weight: 500;
      @extend %link-arrow;
    }
  }
}

.carousel {
  [data-panner-container] {
    @container;

    [data-panner] {
      align-items: flex-start;
    }

    [data-panner-item] {
      @column 6/12 $desktop;
      @space padding-right 1:2/12 $desktop;

      @column 6/12 $tablet;
      @space padding-right 1:2/12 $tablet;

      @column 9/12 $mobile;
      @space padding-right 1:2/12 $mobile;

      overflow: hidden;

      &:last-of-type {
        @space padding-right container;
      }

      figcaption {
        @extend %caption;
      }
    }
  }
}

.carousel-equal-height {
  white-space: nowrap;
  width: 100%;

  [data-panner-container] {
    white-space: nowrap;

    [data-panner] {
      @space margin-left container $desktop;
      @space margin-right container $desktop;

      align-items: flex-start;
      display: flex;

      @responsive $desktop {
        height: 80vh;
        max-height: 700px;
      }

      @responsive $tablet {
        height: 45vh;
        max-height: 500px;
      }

      @responsive $mobile {
        height: 45vh;
        max-height: 500px;
      }
    }

    [data-panner-item] {
      @space padding-right 1:2/12 $desktop;
      @space padding-right 1:2/12 $tablet;
      @space padding-right 1/6 $mobile;
      height: auto;
      min-height: 100%;
      width: auto;
      position: relative;
      flex: 1 1 auto;

      &:first-of-type {
        @space margin-left 1:1/12;
      }

      &:last-of-type {
        @space padding-right container;
      }

      figure {
        height: 100%;
        width: auto;

        img {
          @responsive $desktop {
            height: 80vh;
            max-height: 700px;
          }

          @responsive $tablet {
            height: 45vh;
            max-height: 500px;
          }

          @responsive $mobile {
            height: 45vh;
            max-height: 500px;
          }

          width: auto;
          max-width: none;
        }
      }

      figcaption {
        @extend %caption;
      }
    }
  }
}


.c5 {
  @column 5/12;
}

[data-panner-container] {
  position: relative;

  [data-panner] {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-end;
  }
}

.slider-image-body {
  .header {
    @container
  }

  h2 {
    @font serif h3;
    @space padding-left 1:1/12 $desktop;
    @space margin-bottom 80px $desktop;

    @space padding-left 1:1/12 $tablet;
    @space margin-bottom 80px $tablet;

    @space margin-bottom 30px $mobile;
  }

  [data-panner] {
    white-space: nowrap;
    align-items: center;
  }

  .slide {
    @column 1/1 $desktop;
    @column 1/1 $tablet;
    @responsive $desktop { display: flex }
    @responsive $tablet { display: flex }
    @responsive $mobile {
      width: 200vw;
      display: flex;
      flex-shrink: 0;
    }
    align-self: stretch;

    .slide-inner {
      @responsive $desktop { @container }
      @responsive $tablet { @container }

      display: flex;
      align-items: flex-start;
      white-space: nowrap;
      flex-wrap: nowrap;

      @responsive $mobile {
        flex-direction: row-reverse;
      }

      .text {
        @column 6:1/12 $desktop;
        @space padding-left 1:2/12 $desktop;

        @column 6:1/12 $tablet;
        @space padding-left 1:2/12 $tablet;

        @responsive $mobile {
          @container;
          width: 100vw;
        }

        align-self: stretch;
        white-space: normal;
      }

      figure {
        @column 6/12 $desktop;
        @space padding-left 1:1/12 $desktop;

        @responsive $mobile {
          @container;
          width: 100vw;
        }
      }
    }
  }
}