p {
  @fontsize base;
  @space margin-bottom vertical-rhythm(theme.typography.sizes.base);
  font-weight: 400;

  &:last-of-type {
    margin-bottom: 0 !important;
  }

  a {
    @extend %link;
  }

  > ul > li {
    font-size: inherit;
  }
}

a {
  span.arr {
    display: inline-block;
    transform: rotate(0deg);
    transition: transform 250ms ease-in;
    transform-origin: 50% 50%;
  }

  &:hover {
    span.arr {
      transform: rotate(45deg);
    }
  }
}