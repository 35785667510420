.lightbox-backdrop {
  opacity: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 9999;

  .lightbox-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lightbox-image-wrapper {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .lightbox-image {
      min-width: 1px;
      max-height: 82vh;
      user-select: none;
      pointer-events: none;
      position: fixed;

      @responsive >=desktop_md {
        padding-bottom: 73px;
        padding-top: 73px;
      }

      @responsive ipad_portrait/ipad_landscape {
        padding-bottom: 83px;
        padding-top: 83px;
        max-width: calc(100vw - 80px);
      }

      @responsive <=mobile {
        max-width: calc(100vw);
        padding-top: 100px;
        padding-bottom: 93px;
      }
    }
  }

  .lightbox-next, .lightbox-prev {
    opacity: 0.7;
    color: #000000;
    font-size: 22px;
    letter-spacing: 0.6px;
    line-height: 32px;

    @responsive <=ipad_landscape {
      display: none;
    }
  }

  .lightbox-close {
    font-size: 29px;
    font-weight: bold;
    top: 66px;
    position: absolute;
    right: 100px;
    color: #1E2123;
    z-index: 999999;

    &:hover {
      border: none;
      text-decoration: none;
      color: #000;
    }

    @responsive ipad_portrait/ipad_landscape {
      top: 44px;
      right: 40px;
    }

    @responsive <=mobile {
      top: 40px;
      right: 0;
      left: 0;
      transform: translateX(50%);
      margin-left: -15px;
    }
  }

  .lightbox-next {
    position: absolute;

    @responsive >=desktop_md {
      right: 100px;
    }
  }

  .lightbox-prev {
    position: absolute;

    @responsive >=desktop_md {
      left: 100px;
    }
  }

  .lightbox-caption {
    @fontsize sm;
    text-transform: uppercase;
    position: absolute;
    top: 24px;
  }

  .lightbox-dots {
    position: absolute;
    bottom: 24px;

    a {
      padding: 0 1px;
      color: #AAA39F;
      &.active {
        color: #000;
      }

      &:hover {
        border: none;
        text-decoration: none;
      }
    }
  }
}
