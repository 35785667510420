.image-right {
  @container;
  .inner {
    @column 8/12 $desktop;
    @column-offset 4:1/12 $desktop;

    @column 8/12 $tablet;
    @column-offset 4:1/12 $tablet;

    @column 10/12 $mobile;
    @column-offset 2:1/12 $mobile;


  }
}

.image-full-bleed {
  figcaption {
    @extend %captionEmpty;
  }
}

.image-full-width {
  @container;

  figcaption {
    @extend %caption;
  }
}

.image-image {
  @container;

  .inner {
    @row 2 $desktop;
    @row 2 $tablet;

    figure {
      &:first-of-type {
        @column 6/12 $desktop;

        @column 6/12 $tablet;

        @column 5/6 $mobile;
        @space margin-bottom 60px $mobile;
      }

      &:nth-of-type(2) {
        @responsive $mobile {
          @column-offset 1:1/6;
          @column 5/6;
        }

        @column 6/12 $desktop;
        @space padding-left 1:1/12 $desktop;
      }
    }
  }

  figcaption {
    @extend %captionEmpty;
  }
}

.image-image-smaller {
  @container;

  .inner {
    @row 2 $desktop;
    @row 2 $tablet;

    @responsive $desktop { align-items: flex-end }
    @responsive $tablet { align-items: flex-end }

    figure {
      &:first-of-type {
        @column 6/12 $desktop;
        @space padding-x 1:1/12 $desktop;
        @column 6/12 $tablet;
        @space padding-x 1:1/12 $tablet;

        @column 4/6 $mobile;
      }

      &:nth-of-type(2) {
        @column 4/12 $desktop;
        @column 4/12 $tablet;

        @column 4/6 $mobile;
        @column-offset 2:1/6 $mobile;
        @space margin-top 50 $mobile;
      }
    }
  }

  figcaption {
    @extend %caption;
  }
}

.image-description {
  @container;

  .inner {
    @row 2 $desktop;
    @row 2 $tablet;
    align-items: flex-end;

    figure {
      @column 6/12 $desktop;
      @space padding-x 1:1/12 $desktop;

      @column 6/12 $tablet;
      @space padding-x 1:1/12 $tablet;

      @space padding-x 1:1/6 $mobile;
      @space margin-bottom 35px $mobile;
    }

    article {
      @space padding-right 1/12 $desktop;
      @space padding-right 1/12 $tablet;
      @responsive $mobile {
        text-align: center;
      }

      p {
        @font serif ingressSerif;
      }
    }
  }
}

.image-body {
  @container;
  .inner {
    @row 2 $desktop;
    @row 2 $tablet;

    @responsive $mobile {
      display: flex;
      flex-direction: column-reverse;
    }
    align-items: flex-start;

    figure {
      @column 6/12 $desktop;
      @space padding-left 1:1/12 $desktop;
      @space padding-right 2:1/12 $desktop;

      @column 6/12 $tablet;
      @space padding-left 1:1/12 $tablet;
      @space padding-right 1:1/12 $tablet;

      @column 4/6 $mobile;
      @column-offset 1:1/6 $mobile;
      @space margin-top 90 $mobile;
    }

    article {
      @space padding-right 1/12;
    }
  }
}