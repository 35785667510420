.news-listing {
  @container;
  @space padding-top 200px >=ipad_landscape;
  @space padding-top 150px ipad_portrait;
  @space padding-top 120px $mobile;

  h2 {
    @font serif h2;
    @space margin-bottom 150 >=ipad_landscape;
    @space margin-bottom 80 ipad_portrait;
    @space margin-bottom 80 $mobile;
    text-align: center;
  }

  .tools {
    @space margin-bottom 100;
    .inner {
      @row 3 $desktop;
      @row 3 $tablet;
      align-items: center;

      div {
        @column 4/12 $desktop;
        @column 4/12 $tablet;

        &.year {
          @fontsize kategori/1;
          @responsive $mobile {
            padding-bottom: 35px;
          }

          button {
            padding-bottom: 5px;
            border: none;
            border-bottom: 1px solid #d8d8d8;
            font-weight: 500;

            &:hover, &[data-toggled] {
              border-bottom: 1px solid theme(colors.red);
            }

            span {
              display: inline-block;
              transform: rotate(0deg);
              transition: transform 0.35s ease;
            }

            &[data-toggled] span {
              transform: rotate(180deg);
            }
          }

          ul {
            display: none;
            padding-top: 25px;
            width: 350px;
            position: absolute;

            @responsive $mobile {
              position: relative;
            }

            li {
              @space padding-right 15;

              a {
                @fontsize kategori;
                color: #959595;
                font-weight: 300;

                &:hover, &.active {
                  @color fg black;
                }

                &.active {
                  border-bottom: 1px solid theme(colors.red);
                }

              }
            }
          }
        }

        &.search {
          input {
            width: 100%;
            background-color: #FBF9F9;
            text-align: center;
            border: none;
            padding: 7px 10px;

            @responsive $mobile {
              margin-bottom: 35px;
            }
          }
        }

        &.press {
          @fontsize kategori/1;
          font-weight: 500;
          text-align: right;

          @responsive $mobile {
            text-align: left;
          }

          a {
            padding-bottom: 5px;
            border-bottom: 1px solid #d8d8d8;
            transition: border-bottom 350ms ease;

            &:hover {
              border-bottom: 1px solid theme(colors.red);
            }
          }
        }
      }
    }
  }

  .inner {
    @row 3/wrap >=ipad_landscape;

    article {
      @column 4/12 >=ipad_landscape;
      @space padding-x 0.9 >=ipad_landscape;

      @space margin-bottom 100px >=ipad_landscape;
      @space margin-bottom 100px ipad_portrait;
      @space margin-bottom 60px $mobile;
      @column 8/12 ipad_portrait;
      @column-offset 2:1/12 ipad_portrait;
      text-align: center;

      figure {
        @space margin-bottom 15;
        @extend %zoom;
        @space padding-x 0.9 $mobile;
      }

      img {
        @responsive >=ipad_portrait {
          height: 350px;
          width: 100%;
          object-fit: contain;
          object-position: center bottom
        }

        @responsive $mobile {
          max-width: none;
          min-width: auto;
          width: 100%;
          height: auto !important;
        }
      }

      .date {
        @fontsize bildetekst;
        @color fg red;
        margin-bottom: 10px;
      }

      h2 {
        @font serif h4/1.45;
        @space margin-bottom 15;
      }
    }
  }
}

.news-archive-box {
  @color bg grayLight;
  @space padding-y 100;
  @font serif h3/1;
  text-align: center;
}

.news-latest-listing {
  @container;
  @color bg grayLight;
  @space padding-top 80;
  @space padding-bottom 50;

  [data-script="index"] & {
    @space margin-top blockSpacing;
  }

  .inner {
    > h2 {
      @extend %kategori;
      @space margin-bottom 50px $desktop;
      @space margin-bottom 50px $tablet;
      @space margin-bottom 45px $mobile;
      @color fg red;
    }

    .posts {
      @row 3 $desktop;
      @row 3 $tablet;
    }

    article {
      @column 4/12 $desktop;
      @column 4/12 $tablet;
      @space padding-x 0.9;
      @space margin-bottom 65px $mobile;
      text-align: center;

      figure {
        @space margin-bottom 15;
        @extend %zoom;
      }

      .date {
        @fontsize bildetekst;
        @color fg red;
        margin-bottom: 10px;
      }

      h2 {
        @font serif h4/1.32;
        @space margin-bottom 15;
      }
    }

    .more {
      @space margin-top 50;
      margin: 0 auto;
      text-align: center;
    }

    .arrow {
      @fontsize more/1;
      @extend %link-arrow;
      font-weight: 500;
    }
  }
}