.project-listing {
  @container;
  @space padding-top 200px $desktop;
  @space padding-top 150px $tablet;
  @space padding-top 120px $mobile;
  @space padding-bottom 100;
  border-bottom: 1px solid #000000;

  h2 {
    @font serif h2;
    @space margin-bottom 100;
    text-align: center;
  }

  .filters {
    @space margin-y 50px $desktop;
    @space margin-y 50px $tablet;

    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    button {
      @responsive >ipad_portrait {
        display: none;
      }
      @fontsize stikktittel $mobile;
      @fontsize base ipad_portrait;

      border: none;
      border-bottom: 1px solid #000000;
      margin-bottom: 30px;
      transition: border-bottom 350ms ease;

      span {
        transition: transform 350ms ease;
        display: inline-block;
      }

      &[data-toggled] {
        border-bottom: 1px solid theme(colors.red);
        span {
          transform: rotateZ(-180deg);
        }
      }
    }

    ul {
      display: flex;
      justify-content: space-around;

      @responsive <=ipad_portrait {
        @space margin-bottom 30px;
        flex-wrap: wrap;
        justify-content: flex-start;
        display: none;

        &[data-toggled] {
          display: flex;
        }
      }

      @responsive ipad_portrait {
        max-width: 500px;
      }

      li {
        @fontsize kategori $desktop;
        @fontsize kategori ipad_landscape;
        @fontsize base/1.75 <=ipad_portrait;

        @color fg grayText;

        @responsive <=ipad_portrait {
          display: inline-block;
          width: auto;

          &:after {
            content: '—';
            padding-left: 5px;
            padding-right: 5px;
          }
        }

        a {
          transition: color 350ms ease, border-bottom 350ms ease;

          border-bottom: 1px solid transparent;
          &.active {
            @color fg black;
            border-bottom: 1px solid theme(colors.red);
          }

          &:hover {
            @color fg black;
          }
        }
      }
    }
  }

  .inner {
    display: flex;
    flex-wrap: wrap;

    &.odd {
      .large {
        @column 5/12 $desktop;
        @column-offset 1:1/12 $desktop;

        @column 5/12 $tablet;
        @column-offset 1:1/12 $tablet;

        @space padding-x 1 $mobile;
      }

      article:nth-of-type(5) {
        @column-offset 1:1/12 $desktop;
        @column-offset 1:1/12 $tablet;
        @space margin-right 0;
        align-self: center;
      }
    }

    &.even {
      .large {
        @column 5/12 $desktop;
        @column-offset 1:1/12 $desktop;
        @space margin-right 0 $desktop;

        @column 5/12 $tablet;
        @column-offset 1:1/12 $tablet;
        @space margin-right 0 $tablet;

        @space padding-x 1 $mobile;
      }

      article:nth-of-type(4) {
        @column-offset 1:1/12 $desktop;
        @column-offset 1:1/12 $tablet;
        align-self: center;
      }
    }

    article {
      @column 4/12 $desktop;
      @column 4/12 $tablet;
      @space margin-bottom 100 $desktop;
      @space margin-bottom 100 $tablet;
      @space margin-bottom 50 $mobile;
      @space margin-right 1 $desktop;
      @space margin-right 1 $tablet;
      text-align: left;

      &:nth-of-type(3), &:nth-of-type(8) {
        @space margin-right 0;
      }

      figure {
        @space margin-bottom 15;
        @extend %zoom;
      }

      .date {
        @fontsize bildetekst;
        @color fg red;
        margin-bottom: 10px;
      }

      h2 {
        @font serif h4/1.45;
        margin-bottom: 5px;
        text-align: left;
      }
    }
  }
}