.persons-listing {
  @container;
  @space padding-bottom 50;
  border-bottom: 1px solid #000000;

  .inner {
    @row 4 $desktop;
    @row 4 $tablet;
    @row 2 $mobile;
    flex-wrap: wrap !important;

    article {
      @column 3/12 $desktop;
      @column 3/12 $tablet;
      @column 3/6 $mobile;

      @space margin-bottom 80px $desktop;
      @space margin-bottom 80px $tablet;
      @space margin-bottom 50px $mobile;

      figure {
        @extend %fromGrayscale;
      }

      .separator {
        @responsive $mobile {
          opacity: 0;
          display: block;
          height: 0;
        }
      }

      .name {
        @font serif h5;
        @space margin-top 15;
        font-weight: 500;
        @responsive $mobile {
          letter-spacing: -0.5px;
        }
      }

      .position {
        @space margin-bottom 15;
        @fontsize bildetekst $mobile;
      }

      .email {
        a {
          @extend %link-inverted;
          @fontsize bildetekst $mobile;
          padding-bottom: 2px;
        }
      }

      .phone {
        @fontsize bildetekst $mobile;
      }
    }
  }

  .categories {
    @space margin-bottom 50px $desktop;
    @space margin-bottom 50px $tablet;
    @space margin-bottom 20px $mobile;

    ul {
      display: flex;

      li {
        @fontsize kategori;
        margin-right: 15px;

        a {
          color: #939090;
          transition: color 0.35s ease;

          &:hover {
            color: #000000;
          }

          &[data-toggle-selected] {
            color: #2b2b2b;
            border-bottom: 1px solid theme(colors.red);
          }
        }
      }
    }
  }
}

[data-script="contact"] {
  .hero {
    figure {
      @responsive $mobile {
        height: 250px;

        img {
          object-fit: cover;
          height: 100%;
        }
      }
    }
  }

  .main-studios {
    @container;
    @space margin-top 100px $desktop;
    @space margin-top 100px $tablet;
    @space margin-top 50px $mobile;
    @space margin-bottom 150px $desktop;
    @space margin-bottom 150px $tablet;

    .inner {
      @row 2 $desktop;
      @row 2 $tablet;
      @row 2 $mobile;

      h2 {
        @font serif h3;
        @column 3/12 $desktop;
        @column 3/12 $tablet;
        @column 3/6 $mobile;
      }

      article {
        @row 3 $desktop;
        @row 3 $tablet;

        @column 9/12 $desktop;
        @column 9/12 $tablet;
        @column 3/6 $mobile;

        padding-top: 5px;

        article {
          @column 3/9 $desktop;
          @column 3/9 $tablet;
          @column 6/6 $mobile;
          @space margin-bottom 50px $mobile;

          .social-media {
            @extend %link-inverted;
            margin-right: 12px;
          }
        }
      }
    }
  }
}