footer {
  @container;
  @space padding-top lg;
  @space padding-bottom lg;
  @color bg white;
  @fontsize base/1.5;

  .inner {
    @responsive $desktop { @row 3/wrap }
    @responsive ipad_landscape { @row 3/wrap }
    @responsive <=ipad_portrait {
      display: flex;
      flex-wrap: wrap;
    }
    position: relative;

    .logo {
      @column 2/12 $desktop;
      @column 2/12 ipad_landscape;
      @space margin-bottom 60px $tablet;

      @space margin-bottom 60px <=ipad_portrait;
      @column 6/6 <=ipad_portrait;

      svg {
        @space height 21px <=ipad_portrait;
      }
    }

    .links {
      @row 2 $desktop;
      @column 4/12 $desktop;

      @row 2 ipad_landscape;
      @column 4/12 ipad_landscape;

      @column 3/6 <=ipad_portrait;

      align-items: flex-start;

      .link {
        @column 2/4 $desktop;
        @column 2/4 ipad_landscape;
        @space padding-bottom 15px <=ipad_portrait;
        padding-top: 5px;
      }

      a {
        @font serif h3/1;
        @extend %link;
      }
    }

    .studios {
      @responsive $desktop { @row 3 }
      @column 6/12 $desktop;
      @space padding-top 5px $desktop;

      @responsive ipad_landscape { @row 3 }
      @column 6/12 ipad_landscape;
      @space padding-top 5px ipad_landscape;

      @responsive <=ipad_portrait {
        @column 3/6;
        @column-offset 1;
        margin-top: -85px;
      }

      .studio {
        @column 2/6 $desktop;
        @space margin-bottom 50px <=ipad_portrait;

        &:last-of-type {
          @space margin-bottom 0 <=ipad_portrait;
        }

        .social-media {
          @extend %link-inverted;
          @responsive >=desktop_lg { margin-right: 15px }
          @responsive desktop_md { margin-right: 8px }
          @responsive $tablet { margin-right: 7px }
          @responsive <=ipad_portrait { margin-right: 6px }
          font-weight: 500;
        }
      }
    }
  }

  .aux {
    @row 2;
    @space margin-top 100px $desktop;
    @space margin-top 75px $tablet;
    @space margin-top 50px $mobile;

    @fontsize bildetekst;
    @color fg grayText;
    width: 100%;
    justify-content: space-between;

    @responsive <=ipad_portrait {
      bottom: 0;
    }

    a {
      border-bottom: 1px solid theme(colors.grayText);
      padding-bottom: 2px;
      transition: border-bottom 350ms ease;

      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
  }
}

.clearfix {
  &:after {
    display: block;
    content: '';
    clear: both;
  }
}
