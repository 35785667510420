/**
 * placeholder
**/

main {
  position: relative;
  overflow-x: hidden;
}

#content {
  position: relative;
}

#index {
  position: relative;
}
