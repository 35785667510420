.project-detail {
  > .heading h2 {
    @container;
    @font serif 70px/1.2 desktop_xl;
    @font serif h2/1.26 desktop_lg;
    @font serif h2/1.26 desktop_md;
    @font serif h2/1.26 $tablet;
    @font serif h2/1.26 $mobile;
    @space margin-bottom 100px $desktop;
    @space margin-bottom 100px $tablet;
    @space margin-bottom 70px $mobile;
    @space max-width 70vw >=ipad_portrait;
    text-align: center;
  }

  > figure {
    @space margin-bottom 80px $desktop;
    @space margin-bottom 80px $tablet;
    @space margin-bottom 40px $mobile;
  }

  .shortcuts {
    @container;
    @fontsize ingressSerif;
    @space margin-bottom 150px $desktop;
    @space margin-bottom 150px $tablet;
    @space margin-bottom 90px $mobile;
    font-weight: 300;

    .inner {
      @column 5:1/12 $desktop;
      @column-offset 1:1/12 $desktop;

      @column 5:1/12 $tablet;
      @column-offset 1:1/12 $tablet;

      a {
        @extend %link-inverted;
      }
    }
  }

  .project-data {
    @container;
    @space margin-bottom 150px $desktop;
    @space margin-bottom 150px $tablet;

    .inner {
      @column-offset 1:1/12 $desktop;
      @column-offset 1:1/12 $tablet;

      h2 {
        @color fg red;
        @space margin-bottom 25;
        @extend %kategori;
      }

      article {
        @row 2 $desktop;
        @row 2 $tablet;

        .about {
          @column 7/11 $desktop;
          @column 7/11 $tablet;

          p {
            @font serif ingressSerif/1.57;
          }
        }

        .data {
          @column 4/11 $desktop;
          @column 4/11 $tablet;
          @space padding-left 1:1/11 $desktop;
          @space padding-left 1:1/11 $tablet;

          @responsive $mobile {
            margin-top: 50px;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            margin-bottom: 70px;
            padding-top: 20px;
            padding-bottom: 20px;
          }

          dl {
            @responsive $mobile {
              display: flex;
              align-items: flex-start;
              padding-bottom: 15px;

              &:last-of-type {
                padding-bottom: 0;
              }
            }
          }

          dt {
            @fontsize stikktittel;
            @color fg dark;
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 0.93px;

            @responsive $mobile {
              padding-top: 1px;
              padding-right: 10px;
              padding-left: 15px;
              font-weight: 600;
            }
          }

          dd {
            font-weight: 300;
            @space margin-bottom 30 $desktop;
            @space margin-bottom 30 $tablet;

            @responsive $mobile { font-size: 13px }
          }
        }
      }
    }
  }

  .project-interior {
    h2 {
      @container;
      @extend %kategori;
      @color fg red;
      @space margin-bottom 50;
    }

    [data-panner] {
      white-space: nowrap;
      align-items: center;
      @responsive $mobile {
        align-items: flex-end;
      }
    }

    .slide {
      @column 1/1;
      display: flex;
      align-self: stretch;

      .slide-inner {
        @container;
        display: flex;
        align-items: center;


        @responsive $mobile {
          flex-direction: column-reverse;
          align-items: flex-start;
        }

        .text {
          @font serif ingressSerif;

          @column 6/12 $desktop;
          @space padding-left 1:1/12 $desktop;

          @column 6/12 $tablet;
          @space padding-left 1:1/12 $tablet;

          @responsive $mobile {
            width: 100%;
            text-align: center;
          }

          white-space: normal;
        }

        figure {
          @column 6/12 $desktop;
          @column-offset 1 $desktop;
          @space padding-x 1:1/12 $desktop;

          @column 6/12 $tablet;
          @column-offset 1 $tablet;
          @space padding-x 1:1/12 $tablet;

          @column 6/6 $mobile;
          @space padding-x 1:1/6 $mobile;
          @space margin-bottom 35px $mobile;

          @responsive $mobile {
            flex-basis: 0 !important;
          }
        }
      }
    }
  }

  .project-drawings {
    h2 {
      @container;
      @extend %kategori;
      @color fg red;
      @space margin-bottom 50;
    }

    [data-panner] {
      white-space: nowrap;
      align-items: center;

      max-height: 80vh;
    }

    figcaption {
      @fontsize bildetekst $mobile;
      margin-top: 50px !important;
      white-space: normal;

      h2 {
        @font serif h4/1;
        margin-bottom: 10px;
        font-weight: 500;
      }
    }

    .slide {
      @column 1/1;
      /* max-height: 80vh; */

      .slide-inner {
        @space padding-x container;
        display: flex;
        align-items: center;

        figure {
          @column 12/12;

          img {
            max-width: 900px;
            max-height: 80vh;
            object-fit: contain;

            @responsive $mobile {
              max-width: 90vw;
            }
          }
        }
      }
    }
  }
}

.entry-footer {
  @color bg grayLight;
  @space padding-top 100px $desktop;
  @space padding-top 100px $tablet;
  @space padding-top 80px $mobile;

  .inner {
    @container;

    .info {
      display: flex;
      @responsive $mobile { flex-direction: column }

      aside {
        @column 5/12 $desktop;
        @column-offset 1:1/12 $desktop;
        @column 5/12 $tablet;
        @column-offset 1:1/12 $tablet;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .back {
          @font serif h3/1;
          @space margin-left 5px $mobile;
        }

        .more {
          @space padding-top 35px $mobile;
          @column-offset 1/6 $mobile;
          a {
            @fontsize more/1;
            @extend %link-arrow;
            font-weight: 500;
          }
        }
      }
      .process {
        @column 5/12 $desktop;
        @column-offset 1;
        @column-offset 1/6 $mobile;
        @space margin-top 70px $mobile;
        @font serif h3/1.4;

        a {
          @extend %link-inverted;
        }
      }
    }
  }
}

.other-entries {
  @space margin-top 125;

  > .heading h2 {
    @extend %kategori;
    @space margin-bottom 25;
    @space margin-left container;

    &.news {
      @space margin-bottom 50;
    }
  }

  .projects-listing {
    @space padding-left container;
    @space padding-right container;
    align-items: flex-start !important;
    display: flex;

    article {
      @column 4:1/12 $desktop;
      @space padding-right 1 $desktop;

      @column 4:1/12 $tablet;
      @space padding-right 1 $tablet;

      @column 5:1/6 $mobile;
      @space padding-right 1 $mobile;

      figure {
        @extend %zoom;
        margin-bottom: 10px;
      }

      h2 {
        @font serif h4/1.3;
        @space margin-bottom 15;
        font-weight: 500;
      }
    }
  }

  .news-listings {
    display: flex;
    align-items: flex-start !important;
    @space padding-left container;
    @space padding-right container;
    @responsive $mobile {
      flex-wrap: wrap;
    }

    article {
      @column 4:1/12 $desktop;
      @column 4:1/12 $tablet;
      @space padding-right 1;
      @space margin-bottom 60px $mobile;

      figure {
        @extend %zoom;
        /* @space padding-x 0.72; */
        margin-bottom: 15px;
      }

      h2 {
        @font serif h4/1.3;
        @space margin-bottom 15;
        text-align: center
      }

      p {
        text-align: center;
      }

      .date {
        @extend %kategori;
        @fontsize bildetekst;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
}