.newsletter {
  @space padding-y xl;

  background-color: #fff;
  max-width: 700px;

  article {
    @space padding-x 1 <=mobile;

    @responsive <=mobile {
      @space padding-top lg;
      border: none;
    }

    margin-left: auto;
    margin-right: auto;

    h3 {
      @fontsize base/1.4;
      @space margin-bottom sm;

      margin-top: -11px;
      position: relative;
    }

    p {
      @space margin-bottom sm;
      @fontsize base;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .check-wrap {
      @space padding-y sm;

      display: flex;
      align-items: flex-start;
    }

    label {
      @fontsize base;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    input[type=email] {
      margin-top: 0;
      background-color: white;
      color: theme(colors.blue.500);
      border: 1px solid black;

      &::placeholder {
        color: theme(colors.dark);
        opacity: 0.75;
      }
    }

    input[type=checkbox] {
      display: block;
      margin-right: 25px;
      margin-top: 10px;
    }

    button {
      @space margin-top sm;
      background-color: theme(colors.blue.500);
      border: 1px solid theme(colors.blue.500);

      &.btn-outline {
        color: theme(colors.blue.500);
        border: 1px solid theme(colors.blue.500);
        background-color: #ffffff;
      }
    }
  }
}