section.hero {
  background-color: theme(colors.header.background);
  color: theme(colors.header.foreground);
  position: relative;
  overflow: hidden;

  &.stretch {
    height: 100vh;
    @responsive $mobile {
      height: var(--vp-initial-inner-h);
    }
  }

  h2 {
    @responsive ipad_portrait/ipad_landscape {
      max-width: 500px;
    }

    @responsive <=mobile {
      padding: 0 10%;
    }
  }

  [data-hero-video-pause] {
    display: none;
  }

  .hero-bg {
    @extend %abs100;
    overflow: hidden;
    z-index: 2;

    [data-hero-slider] {
      @extend %abs100;

      [data-hero-slide] {
        @extend %abs100;
        overflow: hidden;
        display: block;

        [data-hero-width] {
          @extend %abs100;

          figure {
            @extend %abs100;
            background-color: #000000;

            img {
              @extend %abs100;
            }
          }
        }
      }
    }

    .info {
      z-index: 9999;
      display: block;
      position: absolute;
      white-space: nowrap;
      text-align: center;

      @responsive >=ipad_portrait {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      @responsive $mobile {
        margin: 0 auto;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        white-space: normal;
      }


      h2 {
        @font serif 70px/1.2 desktop_xl;
        @font serif h2/1.2 desktop_lg;
        @font serif h2/1.2 desktop_md;
        @font serif h2/1.2 $tablet;
        @font serif h2/1.2 $mobile;
        @color fg white;
        @space margin-bottom 25;
        font-weight: 500;
      }

      .more {
        a {
          @fontsize more/1;
          @extend %link-arrow;
          font-weight: 500;
          border-bottom: 1px solid white;
        }
      }
    }

    [data-hero-slide] {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #000;
        opacity: 0.3; /* 0.3 */
        z-index: 2;
        user-select: none;
        pointer-events: none;
      }
    }

    img {
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      transition: transform 11s linear;

      &.zoom {
        transform: scale(1.05) !important;
        transform-origin: 50% 50%;
      }
    }

    video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }

  .hero-content {
    position: static;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    display: flex;

    section.hero-text {
      h2 {
        z-index: 2;
        color: #fff;
        text-align: center;
        padding-bottom: 0;
        font-weight: normal;

        @responsive desktop_lg {
          max-width: 750px;
        }

        @responsive >=desktop_md {
          max-width: 640px;
          font-size: 55px;
          line-height: 80px;
        }

        @responsive ipad_portrait/ipad_landscape {
          font-size: 46px;
          line-height: 60px;
          max-width: 640px;
        }

        @responsive <=mobile {
          font-size: 26px;
          line-height: 39px;
        }
      }
    }

    .centered-arrow {
      @space left container;
      @space bottom container $desktop;
      @space bottom container $tablet;
      @space bottom md $mobile;
      @color fg white;

      z-index: 2;
      position: absolute;
      bottom: 0;
      color: #ffffff;
      text-align: left;
    }
  }
}
