@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 25px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.f {
  animation-name: fadeInUp;
}
