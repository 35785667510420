/**
  S U I S S E ——————————————————————————————————————————————
**/

@font-face {
  font-family: "Suisse";
  font-display: fallback;
  src: url('/assets/fonts/suisse/suisseintl-light-webfont.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Suisse";
  font-display: fallback;
  src: url('/assets/fonts/suisse/suisseintl-regular-webfont.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Suisse";
  font-display: fallback;
  src: url('/assets/fonts/suisse/suisseintl-medium-webfont.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

/**
  S A N G B L E U   K I N G D O M ——————————————————————————————
**/

@font-face {
  font-family: "SangBleu Kingdom";
  font-display: fallback;
  src: url('/assets/fonts/sangbleu/SangBleuKingdom-Regular-WebXL.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SangBleu Kingdom";
  font-display: fallback;
  src: url('/assets/fonts/sangbleu/SangBleuKingdom-Medium-WebXL.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}