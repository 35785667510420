
.employee-detail {
  @container;
  @space padding-bottom 125;

  border-bottom: 1px solid;

  .inner {
    header {
      @row 2 $desktop;
      @row 2 ipad_landscape;

      @space margin-bottom 50;

      .back {
        @column 4/12 $desktop;
        @column 4/12 ipad_landscape;
        @space margin-bottom 50px <=ipad_portrait;

        a {
          @extend %link-inverted;
        }
      }

      .name {
        @responsive <=ipad_portrait {
          display: none;
        }
        h2 {
          @font serif h4/1;
        }
        .position {
          @fontsize ingressGrotesk;
        }
      }
    }

    article {
      @row 2;
      @responsive <=ipad_portrait {
        flex-wrap: wrap !important;
      }

      figure {
        @column 4/12 $desktop;
        @column 4/12 ipad_landscape;
        @column 3/6 <=ipad_portrait;
        @space padding-right 1/12 $desktop;
        @space padding-right 1/12 ipad_landscape;
      }

      .side {
        width: 100%;
        @responsive <=ipad_portrait {
          @column 3/6;
        }
      }

      .name-mob {
        @responsive $desktop { display: none }
        @responsive ipad_landscape { display: none }
        @responsive <=ipad_portrait {
          width: 100%;
        }
        h2 {
          @font serif h4/1;
        }
        .position {
          @fontsize ingressGrotesk;
        }
      }

      .bio-wrapper {
        @row 2 $desktop;
        @row 2 ipad_landscape;
        @space margin-bottom 80;

        @responsive <=ipad_portrait {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
        }

        .data {
          @column 2/8 $desktop;
          @column 2/8 ipad_landscape;
          margin-left: 0 !important;
          @space margin-right 1 $desktop;
          @space margin-right 1 ipad_landscape;

          a {
            @extend %link-inverted;
          }
        }

        .bio {
          @responsive <=ipad_portrait {
            display: none;
          }
        }
      }

      .education-wrapper {
        @row 2 $desktop;
        @row 2 ipad_landscape;

        @responsive <=ipad_portrait {
          display: none;
        }

        h2 {
          @column 2/8 $desktop;
          @column 2/8 ipad_landscape;
          @font serif;
          font-weight: 500;
        }

        .education {
          @column 4/8;
        }
      }

      .bio-mob {
        @responsive $desktop { display: none }
        @responsive ipad_landscape { display: none }
        @responsive <=ipad_portrait {
          @space margin-top 50px;
        }
      }

      .education-mob {
        @responsive $desktop { display: none }
        @responsive ipad_landscape { display: none }
        @responsive <=ipad_portrait {
          @space margin-top 50px;
          margin-left: 0 !important;

          h2 {
            @font serif;
            font-weight: 500;
          }
        }
      }
    }
  }
}