.intro-text {
  @container;
  .inner {
    @column 6:2/12 $desktop;
    @column 6:2/12 $tablet;
    @column-offset 3/12 $desktop;
    @column-offset 3/12 $tablet;
    @space padding-x 0.8 $mobile;

    p {
      @font serif ingressSerif;
      text-align: center;
    }
  }
}

.body-right {
  @container;
  .inner {
    @column 6/12 $desktop;
    @column-offset 6:1/12 $desktop;
    @column 6/12 $tablet;
    @column-offset 6:1/12 $tablet;
    p {
      @fontsize base/1.75 $mobile;
      font-weight: 300;
    }
  }
}

.body-center {
  @container;
  .inner {
    @column 6/12 $desktop;
    @column-offset 3:1/12 $desktop;
    @column 6/12 $tablet;
    @column-offset 3:1/12 $tablet;
    p {
      @fontsize base/1.75 $mobile;
      font-weight: 300;
    }
  }
}

.lede-left {
  @container;
  .inner {
    @column 7/12 $desktop;
    @column-offset 1:1/12 $desktop;
    @column 7/12 $tablet;
    @column-offset 1:1/12 $tablet;
    p {
      @font serif ingressSerif;
    }
  }
}

.news-cover + .lede-left {
  @space padding-top 60px $mobile;
}

.lede-left + .body-left,
.lede-left + .body-right,
.lede-left + .body-center {
  @space padding-top 45px $mobile;
}

.lede-center {
  @container;
  .inner {
    @column 6:2/12 $desktop;
    @column-offset 3/12 $desktop;
    @column 6:2/12 $tablet;
    @column-offset 3/12 $tablet;
    p {
      @font serif ingressSerif;
      text-align: center;
    }
  }
}

.heading-body {
  @container;
  .inner {
    @row 2 $desktop;
    @row 2 $tablet;

    h2 {
      @font serif h3/1;

      @column 6/12 $desktop;
      @space padding-left 1:1/12 $desktop;

      @column 6/12 $tablet;
      @space padding-left 1:1/12 $tablet;

      @space margin-bottom 25 $mobile;
    }
    article {
      @column 6/12 $desktop;
    }
  }
}

