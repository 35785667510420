.entrance {
  &.about {
    @container $desktop;
    @container $tablet;
    @space padding-bottom 0 $mobile;

    .inner {
      @space padding-top 130px $mobile;
      @space padding-bottom 130px $mobile;

      @row 2 $desktop;
      @row 2 $tablet;

      position: relative;
      align-items: center;

      .content {
        @column 6/12 $desktop;
        @column 6/12 $tablet;
        @space padding-x 1;
        @space padding-y 1 $mobile;
        @space margin-x 1 $mobile;
        @responsive $mobile {
          @color bg white;
          align-self: center;
          justify-content: center;
        }

        h2 {
          @fontsize kategori;
          @extend %kategori;
          @space margin-bottom 25;
        }

        .text {
          @font serif ingressSerif;
          @space margin-bottom 50px $desktop;
          @space margin-bottom 50px $tablet;
          @space margin-bottom 30px $mobile;
        }

        .more {
          a {
            @fontsize more/1;
            font-weight: 500;
            @extend %link-arrow;
            @extend %link-inverted;
          }
        }
      }

      figure {
        @column 6/12 $desktop;
        @column 6/12 $tablet;
        @space padding-x 1 $desktop;
        @space padding-x 1 $tablet;
        @responsive $mobile {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: -1;

          img {
            object-fit: cover;
            height: 100%;
          }
        }
      }
    }
  }
}