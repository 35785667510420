/**
 * Application-specific CSS.
 * (c) univers/twined 2019
 */

/**
* Imports
*/

@import './includes/fonts.pcss';
@import './includes/partials.pcss';
@import "./includes/animations.pcss";
@import "./includes/arrows.pcss";
@import "./includes/cookies.pcss";
@import "./includes/containers.pcss";
@import "./includes/content.pcss";
@import "./includes/fader.pcss";
@import "./includes/footer.pcss";
@import "./includes/general.pcss";
@import "./includes/header.pcss";
@import "./includes/headers.pcss";
@import "./includes/lazyload.pcss";
@import "./includes/lightbox.pcss";
@import "./includes/modules.pcss";
@import "./includes/newsletter.pcss";
@import "./includes/paragraphs.pcss";
@import "./includes/popup.pcss";
@import "./includes/navigation.pcss";
@import "./includes/detail/_contact.pcss";
@import "./includes/detail/_news.pcss";
@import "./includes/detail/_page.pcss";
@import "./includes/detail/_project.pcss";
@import "./includes/matrix/_base.pcss";
@import "./includes/matrix/_entrances.pcss";
@import "./includes/matrix/_images.pcss";
@import "./includes/matrix/_panners.pcss";
@import "./includes/matrix/_text.pcss";
@import "./includes/listings/_contact.pcss";
@import "./includes/listings/_news.pcss";
@import "./includes/listings/_projects.pcss";

@europa arrows;
@europa base;

/* lazyload fix for chrome. sometimes IntersectionObserver doesn't pick up the image */
img[data-src], img[data-srcset] { min-height: 1px; min-width: 100%; }

.no-js body.unloaded {
  opacity: 1;
}

.no-js body.unloaded header {
  opacity: 1;
}

.no-js body.unloaded .navbar {
  transform: none;
}

html, body {
  min-height: 100%;
}

body {
  main {
    @responsive $desktop { padding-top: 210px }
    @responsive $tablet { padding-top: 175px }
    @responsive $mobile { padding-top: 115px }
  }

  &[data-script="index"] {
    main {
      padding-top: 0;
    }
  }

  &[data-script="page-detail"] {
    main {
      padding-top: 50px;
    }
  }
}

html {
  @iterate theme.typography.lineHeight {
    @responsive $key {
      line-height: $value;
    }
  }
}

svg:not(:root) {
  overflow: hidden;
}

[data-outline="false"] {
  video {
    outline: none !important;
  }
}

html.moonwalk {
  [data-moonwalk=""] {
    transform: translateY(5px);
    transition: transform 0.65s cubic-bezier(0.39, 0.575, 0.565, 1),
                 opacity 0.65s cubic-bezier(0.47, 0, 0.745, 0.715);
    transition-delay: 0.05s;
    opacity: 0;

    &.moonwalked {
      transform: translateY(0);
      opacity: 1;
    }
  }

  [data-moonwalk="slide"] {
    transform: translateX(-15px);
    transition: transform 0.65s cubic-bezier(0.39, 0.575, 0.565, 1),
                 opacity 0.65s cubic-bezier(0.47, 0, 0.745, 0.715);
    opacity: 0;

    &.moonwalked {
      transform: translateX(0);
      opacity: 1;
    }
  }

  [data-moonwalk="slideLeft"] {
    transform: translateX(50px);
    transition: transform 0.65s cubic-bezier(0.39, 0.575, 0.565, 1),
                 opacity 0.65s cubic-bezier(0.47, 0, 0.745, 0.715);
    opacity: 0;

    &.moonwalked {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid #000000;
  margin: 0;
}

.video-wrapper {
  video {
    width: 100%;
  }
}

[data-smart-video] {
  position: relative;
  opacity: 0;
  visibility: hidden;

  video {
    top: 0;
    width: 100%;
    position: absolute;
    z-index: 3;
  }
}

.no-js [data-smart-video] {
  opacity: 1 !important;
  visibility: visible !important;
}

.embed-video-wrapper {
  @embed-responsive 16/9;
}

@responsive <=mobile {
  ul li {
    list-style-position: inside;
  }
}

a.plain {
  color: inherit;
  border: none;
  text-decoration: none;

  &:hover {
    border: none;
    text-decoration: none;
  }
}

body {
  background-color: theme(colors.body.background);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @fontsize base;
}

p:last-child {
  margin-bottom: 0;
}

.blink {
  animation: blink-animation 2.7s steps(200, start) infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }

  50% {
    opacity: .8;
  }
}

header {
  opacity: 1;
  transition: opacity 2s ease;
}

.ft {
  display: inline-block;
  white-space: nowrap;
}

.c {
  align-self: center;
}
