.no-js header[data-nav] nav section ul li a {
  color: white;
}

.no-js [data-script="about"] header[data-nav] nav section ul li a {
  color: black;
}

.no-js {
  header[data-nav] {
    background-color: transparent !important;
    color: theme(colors.light) !important;
    /* padding-top: 30px !important;
    padding-bottom: 30px !important; */
    position: absolute;

    nav {
      article.brand {
        @color fg white;
      }

      figure.menu-button {
        top: 0;
      }
    }

    @responsive >=desktop_md {
      .hamburger {
        display: none;
      }
    }

    @responsive <=mobile {
      #menu:target {
        display: block;
        align-items: flex-start;
      }
    }

    @responsive <=ipad_landscape {
      #menu {
        .no-js-menu {
          display: block;
        }
        .no-js-menu-close {
          display: none;
        }

        .mobile-bg {
          display: none;
          transform: translateX(100%);
        }

        .mobile-bg-noscript {
          display: none;
          transform: translateX(100%);
        }

        &:target {
          align-items: flex-start;

          .no-js-menu {
            display: none;
          }
          .no-js-menu-close {
            display: block;
          }

          opacity: 1;
          height: 100vh;

          .mobile-bg-noscript {
            display: block;
            transform: translateX(0);
          }

          section {
            display: flex;

            &:first-of-type {
              padding-top: 50px;
            }
          }
        }
      }

      .no-js-menu, .no-js-menu-close {
        right: 0;
        position: absolute;
        z-index: 9999;
      }

      .no-js-menu,  {
        .hamburger-inner::before, .hamburger-inner::after {
          background-color: #000 !important;
        }
      }

      .no-js-menu-close {
        .hamburger-inner::before, .hamburger-inner::after {
          background-color: #000 !important;
        }
      }
    }
  }
}